.switch-wrapper {
}

.thumbCircleExternal {
  background-color: #b3e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.5rem;
  height: 11.5rem;
  border-radius: 50%;
  transition: background-color 0.5s ease;
}

.thumbCircleInternal {
  background: #239c8d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  transition: background-color 0.5s ease;
}

.switch {
  display: flex;
  position: relative;
  width: 380px;
  height: 185px;
  margin-bottom: 0;
  border-radius: 9999px;
  padding: 1px 20px;
  align-items: center;
  justify-items: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switchBg {
  background-color: #c3ced0;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 40rem;
  height: 80px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: left;
}

.switchText {
  color: #677884;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 0 35px;
  max-width: 170px;
  text-align: left;
}

.switchUnchecked .switchBg {
  justify-content: left;
}

.switchChecked .switchBg {
  justify-content: right;
}

.switchChecked .thumbCircleExternal {
  background-color: #b3e1e1;
}
.switchChecked .thumbCircleInternal {
  background-color: #239c8d;
}
.switchUnchecked .thumbCircleExternal {
  background-color: #ebeeee;
}
.switchUnchecked .thumbCircleInternal {
  background-color: #d72735;
}
.switchOffline .thumbCircleExternal {
  background-color: #ebeeee;
}
.switchOffline .thumbCircleInternal {
  background-color: #bdbdbd;
}

.switchLoading.switchChecked .switchBg {
  justify-content: left;
}

.switchLoading.switchUnchecked .switchBg {
  justify-content: right;
}

.switchInput {
  width: 0;
  height: 0;
  overflow: hidden;
}

.switchHandle {
  top: 3px;
  left: 3px;
  position: absolute;
  border-radius: 50%;
  transform: translateX(0);
  outline: none;
}
