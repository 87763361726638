.cancelButton {
  border: none;
  background-color: transparent;
  color: red;
  font-weight: bold;
  transition: color 300ms;
  cursor: pointer;
  outline: none;
  font-size: 14px;
}

.cancelButton:hover {
  color: var(--vermelho-contorno);
}

.dialog div:first-child {
  border-radius: 10px;
}

.head {
  width: 520px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 10px;
  padding-top: 5px;
}

.closeIcon {
  color: #aea3a3;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid red;
  padding: 0.5rem;
}

.circle svg {
  font-size: 3rem;
  color: red;
}

.iconButton {
  color: #888888;
}
