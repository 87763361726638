body {
  background-color: #fff;
}

.MuiDrawer-paper {
  background-color: #00a335 !important;
}

/* Works on Firefox */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

.MuiFilledInput-underline:before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

label.MuiFormLabel-root.Mui-focused {
  color: black;
}

.makeStyles-content-10 {
  flex: 1;
  margin-top: 63.993px;
  background-color: #ebeeee;
}

.MuiTypography-displayBlock {
  display: block;
  font-size: small !important;
}

.bcXoYc {
  padding: 0 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #00a335 !important;
  pointer-events: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00a335 !important;
  text-shadow: 0 0 0.25px currentColor;
}

* {
  font-family: "Open Sans" !important;
  scrollbar-width: thin;
  scrollbar-color: #cbdee7 #eff7fc;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  max-height: 10px;
}

*::-webkit-scrollbar-track {
  background: #eff7fc;
}

*::-webkit-scrollbar-thumb {
  background-color: #00a335;
  border-radius: 20px;
  border: 1px solid #eff7fc;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #c4c4c4 !important;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #00a335 !important;
  border: none;
  border-radius: 0;
}

.MuiGrid-spacing-xs-3 {
  width: calc(100% + 12px) !important;
  margin: -12px;
}

.MuiBreadcrumbs-root {
  box-shadow: none !important;
  margin-left: -20px !important;
}

.custom-active-menu {
  background-color: #007727 !important;
}

/** classes utilitárias **/
.m-0 {
  margin: 0;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.p-0 {
  padding: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3e5055;
}
