.footer-record-container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  padding: 0 2rem;
  margin: 0px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
}

.flex div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typography {
  margin-left: 10px;
}

.button {
  font-weight: bold;
  margin-left: 8px;
  color: #fff;
  border-radius: 50px;
  width: 10rem;
}
